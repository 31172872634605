/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */


import React, { useEffect } from "react";
import { navigate } from "@gatsbyjs/reach-router";

export const onInitialClientRender = () => {
    /*   const urlParams = new URLSearchParams(window.location.search);
       const open = urlParams.get("open"

        if (open) {
           // Define las reglas de redirección aquí
           if (open.includes("whatsapp")) {
               // Redirige a la nueva URL
               navigate("https://api.whatsapp.com/send?phone=34682119659&text=Buenas!%0aMe%20gustar%C3%ADa%20reservar%20una%20cita%20para%20el%20tratamiento%20reductor%20manual,%20que%20he%20visto%20en%20la%20web");
           }
       }*/
};








// You can delete this file if you're not using it


// exports.shouldUpdateScroll = ({
//     routerProps: { location },
//     getSavedScrollPosition,
//   }) => {
//     const { pathname } = location
//     // list of routes for the scroll-to-top-hook
//     const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
//     // if the new route is part of the list above, scroll to top (0, 0)
//     //if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//       //window.scrollTo(0, 0)
//     //}

//     window.history.scrollRestoration = "manual"
//     window.scrollTo(0, 0)

//     //const savedPosition = getSavedScrollPosition(location)
//     //console.log('location'+ JSON.stringify(location))
//     //console.log(savedPosition )

//     //window.history.scrollRestoration = "manual"
//     //window.scrollTo(0, 150)
//     //const body = document.getElementsByTagName('body')[0]
//     //body.scrollTop = 0
  
//     return false
//   }
