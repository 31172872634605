exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lista-de-precios-js": () => import("./../../../src/pages/lista-de-precios.js" /* webpackChunkName: "component---src-pages-lista-de-precios-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../../../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-tratamientos-corporales-drenaje-linfatico-index-js": () => import("./../../../src/pages/tratamientos-corporales/drenaje-linfatico/index.js" /* webpackChunkName: "component---src-pages-tratamientos-corporales-drenaje-linfatico-index-js" */),
  "component---src-pages-tratamientos-corporales-index-js": () => import("./../../../src/pages/tratamientos-corporales/index.js" /* webpackChunkName: "component---src-pages-tratamientos-corporales-index-js" */),
  "component---src-pages-tratamientos-corporales-maderoterapia-index-js": () => import("./../../../src/pages/tratamientos-corporales/maderoterapia/index.js" /* webpackChunkName: "component---src-pages-tratamientos-corporales-maderoterapia-index-js" */),
  "component---src-pages-tratamientos-corporales-masaje-descontracturante-index-js": () => import("./../../../src/pages/tratamientos-corporales/masaje-descontracturante/index.js" /* webpackChunkName: "component---src-pages-tratamientos-corporales-masaje-descontracturante-index-js" */),
  "component---src-pages-tratamientos-corporales-masaje-reductor-madrid-index-js": () => import("./../../../src/pages/tratamientos-corporales/masaje-reductor-madrid/index.js" /* webpackChunkName: "component---src-pages-tratamientos-corporales-masaje-reductor-madrid-index-js" */),
  "component---src-pages-tratamientos-corporales-presoterapia-index-js": () => import("./../../../src/pages/tratamientos-corporales/presoterapia/index.js" /* webpackChunkName: "component---src-pages-tratamientos-corporales-presoterapia-index-js" */),
  "component---src-pages-tratamientos-corporales-reflexologia-podal-index-js": () => import("./../../../src/pages/tratamientos-corporales/reflexologia-podal/index.js" /* webpackChunkName: "component---src-pages-tratamientos-corporales-reflexologia-podal-index-js" */),
  "component---src-pages-tratamientos-faciales-index-js": () => import("./../../../src/pages/tratamientos-faciales/index.js" /* webpackChunkName: "component---src-pages-tratamientos-faciales-index-js" */),
  "component---src-pages-tratamientos-faciales-limpieza-facial-index-js": () => import("./../../../src/pages/tratamientos-faciales/limpieza-facial/index.js" /* webpackChunkName: "component---src-pages-tratamientos-faciales-limpieza-facial-index-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

